import React, { useEffect, useState } from "react";
import "../../css/main.css";
import LeftPan from "./LeftPan";
// import RightPan from "./RightPan";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import TopicList from "./TopicList";
import { fetchErroredProblems, fetchTopics } from "../../action/content";
import SeriesList from "./SeriesList";
import ProblemContainer from "./ProblemContainer";
import sir_image from "../../resources/zehady.jpg";
import { fetchProfile, fetchStats } from "../../action/profile";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../action/auth";
import TestContainer from "./test/TestContainer";
import En from "./En";
import Bn from "./Bn";
import ScoreDetails from "../ScoreDetails";
import propic from "../../assets/login username.png";
import Levels from "./Levels";
import ProgressTracking from "../../pages/progress-tracking";

import UserDashboard from "../../pages/user-dashboard";
import RightPanUpdated from "./RightPanUpdated";
import UnsolvedProblems from "../../pages/unsolved-problems";

const Main = (props) => {
  const profile = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const [history, setHistory] = useState(props.history);

  useEffect(() => {
    fetchProfile(dispatch);
    fetchStats(dispatch);

    if (history.location.pathname === "/") {
      history.push("/lang/en/level/1");
    }
  }, []);

  useEffect(() => {
    setHistory(props.history);
  }, [props.history]);

  useEffect(() => {
    if (profile !== null)
      fetchErroredProblems(dispatch, {
        user_id: profile.user_id,
      });
    //console.log(profile)
  }, [profile]);

  return (
    <BrowserRouter history={history}>
      <div className={"main-container"}>
        <div className={"left-container"}>
          <Route component={LeftPan} />
        </div>
        <div className={"mid-container"}>
          <div className={"main-header"}>
            <div className={"levels-Container"}>
              <Route component={Levels} />
            </div>
            <div className={"main-image-container"}>
              <div className={"main-image-image"}>
                <img
                  src={
                    profile !== null && profile.image !== null
                      ? profile.image
                      : propic
                  }
                />
              </div>
              <div
                className={"main-image-xp"}
                onClick={() => {
                  logout(dispatch,history);
                }}
              >
                Logout
              </div>
            </div>
          </div>
          <span className="mid-body-line"></span>
          <div className={"mid-body"}>
            <Switch>
              <Route
                path="/lang/:lang/level/:level/series/:series_id/problem/:serial"
                exact
                component={ProblemContainer}
              />
              <Route
                path="/lang/:lang/problem/solved"
                exact
                component={UserDashboard}
              />
              <Route
                path="/lang/:lang/problem/unsolved"
                exact
                component={UnsolvedProblems}
              />
              <Route
                path="/lang/:lang/level/:level/test/:test_id"
                exact
                component={TestContainer}
              />
              <Route
                path="/lang/:lang/level/:level/topic/:id"
                exact
                component={SeriesList}
              />
              <Route
                path="/lang/:lang/level/:level"
                exact
                component={TopicList}
              />
              <Route
                path="/lang/:lang/progress-tracking"
                exact
                component={ProgressTracking}
              />
            </Switch>
          </div>
        </div>
        <div className={"right-container"}>
          <RightPanUpdated />
        </div>
      </div>
      <Switch>
        <Route path="/lang/en">
          <En />
        </Route>
        <Route path="/lang/bn">
          <Bn />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
