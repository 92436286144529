import React from 'react';
import '../../../css/ButtonSection.css';

const ButtonSection = ({ onSubmit, onLater }) => {
  return (
    <div className="button-section">
      <button 
        className="button-section__later" 
        onClick={onLater}
      >
        Later
      </button>
      <button 
        className="button-section__submit" 
        onClick={onSubmit}
      >
        Submit & Continue
      </button>
    </div>
  );
};

ButtonSection.defaultProps = {
  onSubmit: () => {},
  onLater: () => {}
};

export default ButtonSection;

