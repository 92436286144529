import React, { useState } from 'react';
import CalendarHeader from './CalendarHeader';
// import WeekDaysHeader from './WeekDaysHeaderr';
import CalendarDays from './CalendarDays';
import '../../../css/Calendar.css';

const Calendar = ({setShowCalendar,onDateSelect,setSelectedDate}) => {
  // const [selectedDate, setSelectedDate] = useState(new Date());

  // const handleDateSelect = (date) => {
  //   setSelectedDate(date);
  // };

  return (
    <div className="calendar">

      <CalendarDays 
      setShowCalendar={setShowCalendar}
      onDateSelect ={onDateSelect}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
};

export default Calendar;

