import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../css/CalendarDays.css';
import left from "../../../assets/left.png";
import right from "../../../assets/right.png";

const CalendarDays = ({ setShowCalendar, onDateSelect}) => {
  const [currentDate,setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const generateDays = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDay = getFirstDayOfMonth(currentDate);
    const days = [];
    
    // Add empty cells for days before the first day of month
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Add actual days
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected = day === selectedDate.getDate() && 
                        currentDate.getMonth() === selectedDate.getMonth() && 
                        currentDate.getFullYear() === selectedDate.getFullYear();
      
      days.push(
        <div
          key={day}
          className={`calendar-day ${isSelected ? 'selected' : ''}`}
          onClick={() => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
        onDateSelect(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
          }}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
  };

  const generateMonthOptions = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      months.push(
        <option key={i} value={i}>
          {new Date(2000, i).toLocaleString('default', { month: 'long' })}
        </option>
      );
    }
    return months;
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 50; i <= currentYear ; i++) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  const handleMonthChange = (e) => {
    setCurrentDate(new Date(currentDate.getFullYear(), parseInt(e.target.value), 1));
  };

  const handleYearChange = (e) => {
    setCurrentDate(new Date(parseInt(e.target.value), currentDate.getMonth(), 1));
  };

  const handleSelect = () => {
    // Format date as DD/MM/YYYY
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    console.log(formattedDate )
    // setSelectedDate(formattedDate); // U
    setShowCalendar(false); // Close the calendar
    onDateSelect(currentDate, formattedDate); // Optional callback
  };

  const handleCancel = () => {
    setShowCalendar(false)
  };

  return (
    <div className="calendar-days">
      <div className="calendar-navigation">
        <button onClick={handlePrevMonth} className="nav-button">
          <img src={left} alt="Previous" />
        </button>
        <select 
          value={currentDate.getMonth()} 
          onChange={handleMonthChange}
          className="current-month"
        >
          {generateMonthOptions()}
        </select>
        <select 
          value={currentDate.getFullYear()} 
          onChange={handleYearChange}
          className="current-month"
        >
          {generateYearOptions()}
        </select>
        <button onClick={handleNextMonth} className="nav-button">
          <img src={right} alt="Next" />
        </button>
      </div>
      <div className="weekdays">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="weekday">{day}</div>
        ))}
      </div>
      <div className="days-grid">
        {generateDays()}
      </div>
      <div className="calendar-buttons">
        <button className="calendar-button cancel" onClick={handleCancel}>Cancel</button>
        <button className="calendar-button select" onClick={handleSelect}>Select</button>
      </div>
    </div>
  );
};

CalendarDays.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onDateSelect: PropTypes.func,
  setShowCalendar: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default CalendarDays;

