import {
    Link,
    Button,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
  } from "react-scroll";
  import React from "react";
  import Toolbar from "@material-ui/core/Toolbar";
  
  import AppBar from "@material-ui/core/AppBar";
  import Dialog from "@material-ui/core/Dialog";
  import DialogTitle from "@material-ui/core/DialogTitle";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogActions from "@material-ui/core/DialogActions";
  import IconButton from "@material-ui/core/IconButton";
  import CloseIcon from "@material-ui/icons/Close";
  import Typography from "@material-ui/core/Typography";
  import cancel_icon from "./img/cancel icon.svg";
  import logo from "./img/main_logo.svg";
  import logo_with_text from "./img/main_logo_text.svg";
  import { makeStyles } from "@material-ui/core/styles";
  import { useHistory } from "react-router-dom";
  // let scrollSpy = Scroll.scrollSpy;
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const Login2 = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleAbout = () => {
      history.push("/about");
    };
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    const humburgerBtn = () => {
      document.querySelector("#hamburgermenu").classList.toggle("menu_active");
      document.body.style.overflow = "hidden";
    };
    const hamburgerClsBtn = () => {
      document.querySelector("#hamburgermenu").classList.remove("menu_active");
    };
  
    return (
      <div className="homepage-nav">
       h
       
        {/* <Dialog
          fullScreen
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                About Us
              </Typography>
            </Toolbar>
          </AppBar>
  
          <DialogContent dividers>
            <Typography gutterBottom>
              <p>
                Brainlytic offers creative mathematical and logical problems (with
                real-world contexts) to young learners of classes 3 to 10, in both
                English and Bengali. The use of an interactive interface during
                problem solving helps in visualization, and exploration of new
                possibilities. We can also track and display a user’s progress and
                performance. The problems have hints, and come with detailed
                explanations for a beginner-friendly learning experience. Prior
                mistakes are easily accessible for revision and one can share
                interesting problems with peers.
              </p>
              <p>
                For many, the focus of education has shifted to examination and
                memorization, rather than creating love and attraction for
                learning. Many children only practice for the exams and hardly get
                the chance to think outside the box. Again, many enthusiastic
                learners get discouraged seeing the steep learning curve of
                high-standard programs like Math Olympiad. Traditional means of
                teaching are also not favorable for students with attention
                deficit; more so in the online terms due to an increase in
                screen-time.
              </p>
              <p>
                We believe, the solution lies in a platform with a large
                assortment of creative problems of varied difficulty levels. An AI
                guide to monitor the student’s progress will inspire learners of
                all skill levels to participate. With an interactive approach, the
                learners with attention deficit will feel as if they are “Playing
                games”, while actually learning new mathematical concepts and
                practicing logical thinking (Reference). Brainlytic incorporates
                all of these features, bringing you a platform that respects every
                individual’s growth speed, encourages out-ofthe-box thinking, and
                aims to nurture logical thinking and mathematical reasoning.
              </p>
            </Typography>
          </DialogContent>
        </Dialog> */}
      </div>
    );
  };
  
  export default Login2;
  