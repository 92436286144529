import React from 'react';
import '../../../css/Header.css';
import tick from "../../../assets/right-arrow.png";
import separator from "../../../assets/separator.png";
import progress_bar from "../../../assets/progress_bar.png";


const Header = ({ currentStep = 2 }) => {
  return (
    <div className="header-container">
      <div className="header-content">
        <div className="header-step">
          <div className="step-indicator completed">
            <img src={tick} alt="check" className="check-icon" />
          </div>
          <span className="step-text completed">Create Account</span>
        </div>
        
        <div className="separator-container">
          <img src={separator} alt="separator" className="separator" />
        </div>
        
        <div className="header-step">
          <div className="step-indicator current">
            <span className="step-number">2</span>
          </div>
          <span className="step-text current">Basic Information</span>
        </div>
      </div>
      <div className="progress-bar-container">
        <img src={progress_bar} alt="progress" className="progress-bar" />
      </div>
    </div>
  );
};

Header.defaultProps = {
  currentStep: 2
};

export default Header;
