import React, { useState } from "react";
import { base_url } from "../..";
import Button from '@material-ui/core/Button';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import "../../css/right-pan.scss";
import "../../css/left-pan.css";
import InfoIcon from '@material-ui/icons/Info';
import achievement_icon from "../../assets/achievement.png";
import badge_icon from "../../assets/badge.png";
import LeftPan from "./LeftPan";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import fire_icon from "../../assets/fire-icon.png";
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  gridCentering: {
    [theme.breakpoints.down("1280")]: {
      justifyContent: "center",
    },
  },
}));

const Stats = (props) => {
  const stat = useSelector((state) => state.stat);
  const history = useHistory();
  const [scoreData, setScoreData] = useState([]);
  const [activeLevel, setActiveLevel] = useState(0);
  const fetchScoreData = () => {
    axios
      .get(base_url + "api/score/score-summary/", {
        headers: { authorization: cookies.get("token") },
      })
      .then((res) => {
        setScoreData(res.data);
      });
  };

  useEffect(() => {
    fetchScoreData();
  }, []);

  const classes = useStyles();
  return (
    <div style={{ marginTop: "40px" }}>
      <div className={"achievement-header"}>
        {/* <div className={"achievement-title"}>Progress Summary</div> */}
      </div>
      <div className="achievement-score">
        <h2 className="achievement-score-heading">Your Score</h2>
        <div className="achievement-statistics-levelbar">
          {scoreData.map((data, index) => {
            return (
              <p
                key={data.level}
                onClick={() => {
                  setActiveLevel(index);
                }}
                className={index === activeLevel ? "activelevel" : ""}
              >
                {data.level}
              </p>
            );
          })}
        </div>
        <div className="achievement-statistics-cards">
          {scoreData[activeLevel] &&
            scoreData[activeLevel].topics.map((data) => (
              <ScoreBar
                score={data.score}
                topic={data.topic}
                key={data.topic}
              />
            ))}
        </div>

        <div style={{ display: 'flex', gap: '10px' }}>
        <Button
  // className="achievement-button"
  // color=""
  variant="contained"
  style={{ 
    width: "165px",
    marginTop: "16px",
    height: "55px", 
    fontSize: "13px",
    backgroundColor:"#3d7eff",
    color:"white"  // Adjust this value as needed
  }}
  startIcon={<InfoIcon />}
  onClick={() => {
    history.push("/lang/en/problem/solved");
  }}
>
  Details
</Button>
<Button
  variant="contained"
  // className="achievement-button"
  style={{ 
    width: "165px",
    marginTop: "16px",
    height: "55px", 
    fontSize: "13px",
    backgroundColor:"#3d7eff",
    color:"white"  // Adjust this value as needed
  }}
  startIcon={<TrendingUpIcon/>}
  onClick={() => {
    history.push("/lang/en/progress-tracking");
  }}
>
  Track Progress
</Button>

</div>

      </div>
    </div>
  );
};

const ScoreBar = ({ score, topic }) => {
  return (
    <div className="scorebar">
      <p>{topic}</p>
   
      <h3>{score}%</h3>
    </div>
  );
};

const RightPanUpdated = (props) => {
  return (
    <div className={"right-main-container"}>
      <Stats />
  
      {/* <div className="left-pan-right">
          <LeftPan />
        </div> */}
           {/* <div className={"right-list-container-header-title"}>
           Notifications
          </div>
          
      <div>
      
    
      <div>
                <NotificationCard />
       </div>
       <div>
                <NotificationCard />
       </div>
       <button
          className="achievement-button"
          // onClick={() => {
          //   history.push("/lang/en/problem/unsolved");
          // }}
          // style={{ marginBottom: "10px" }}
        >
          Show all
        </button>
    </div> */}
      </div>
    
  );
};
const NotificationCard = (props) => {
  //console.log(props.problem)

  return (
    <div
      onClick={() => {
        // props.history.push(
        //   `/lang/en/level/${props.problem.grade}/series/${props.problem.series_id}/problem/${props.problem.serial}`
        // );
      }}
      className={"left-problem-card-container"}
    >
     
      <div className={"left-problem-card-divider"} />
      <div className={"left-problem-card-bottom-container"}>
        <div className={"left-problem-card-title"}>
          Announcement on Online Test
        </div>
        <div className={"left-problem-card-description"}>
          An online test will be held in the next week. 
        </div>
        <div className={"left-problem-card-bottom"}>
          <div className={"left-problem-card-bottom-icon"}>
          <Button
  variant="contained"

  startIcon={<InfoIcon />}
  // onClick={() => {
  //   history.push("/lang/en/problem/solved");
  // }}
  style={{ 
    width: "140px", 
    height: "35px", 
    fontSize: "13px",
    backgroundColor:"#3d7eff",
    color:"white"  
  }}
>
  Details
</Button>

          </div>
        
        </div>
      </div>
    </div>
  );
};
export default RightPanUpdated;
