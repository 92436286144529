import React, { useState ,useEffect} from 'react';
import '../../css/forgotPass.css';
import eye from "../../assets/eye.png"
import google from "../../assets/google.png"
import fb from "../../assets/fb.png"
import mail from "../../assets/mail.png"
import Eye_closed from "../../assets/eye-closed.png"
import GoogleLogin from "react-google-login";
import {googleAuth, login} from "../../action/auth";
import {useDispatch} from "react-redux";
import {showToast} from "../../App";
const ForgotPass= ({ onSubmit,history }) => {

  const dispatch=useDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

const otpClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        showToast("Please enter a valid email address", "error");
        return;
    }
    history.push('/auth/otp')
}
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({ email, password });
    }
  };

  const googleAuthComplete=res=>{
    //console.log(res)
    if(res.accessToken!=undefined) {
        googleAuth({access_token: res.accessToken}, dispatch,history)
    }
}

  return (
    <div className="login-form">
      <div className="login-header">
        <h1>Forgot Password?</h1>
        <p style={{width:"400px", fontSize:"15px"}}>No worries! Let’s reset your password with your email</p>
      </div>

    
      <form onSubmit={handleSubmit} className="login-form-fields">
        <div className="input-group">
          <label htmlFor="email">Email Address</label>
          <div className="input-field">
            <input
              id="email"
              type="email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src={mail} alt="email" className="field-icon" />
          </div>
        </div>

      

        
        <button type="submit" className="sign-in-button" onClick={otpClick}>
          Send OTP
        </button>
      </form>

      <div className="signup-section">
        
        <button className="sign-up-button" onClick={() => history.goBack()}>Cancel</button>
      </div>
    </div>
  );
};

ForgotPass.defaultProps = {
  onSubmit: () => {},
  initialEmail: '',
  initialPassword: '',
  allowSocialLogin: true,
  allowPasswordReset: true,
  allowSignUp: true
};

export default ForgotPass;
