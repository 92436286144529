import React from 'react';
import '../../css/HeaderSection.css';
import br from "../../assets/br.png"
import brainlytic from "../../assets/brainlytic.png"
const HeaderSection = ({ title, description }) => {
  return (
    <div className="header-section">
      <div className="logo-container">
        <img src={br} alt="Logo" className="logo" />
        <div className="brand-container">
          <img src={brainlytic} alt="Brainlytic" className="brand-logo" />
        </div>
      </div>
      
      <div className="content-container">
      <h1 className="title">
          { "Your Journey to Greatness Starts here"}
        </h1>
        {/* <h1 className="title">
          {/* {title || "Your Journey to Greatness Starts here"} */}
        {/* </h1>  */}
        {/* <p className="description">
          {description || "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
        </p> */}
      </div>
    </div>
  );
};

HeaderSection.defaultProps = {
  title: "Your Journey to Greatness Starts here",
  description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
};

export default HeaderSection;

