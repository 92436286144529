import React, { useState } from 'react';
import Header from './Header';
import TitleSection from './TitleSection';
import FormSection from './FormSection';
import ButtonSection from './ButtonSection';
import '../../../css/OnBoarding.css';

const OnBoarding = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    dob: '',
    class: '',
    school: ''
  });

  const handleInputChange = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value
    });
  };

  const handleSubmit = () => {
    console.log('Form submitted:', formValues);
  };

  const handleLater = () => {
    console.log('Submit later');
  };

  return (
    <div className="main-container-onboarding">
      <Header currentStep={2} />
      <TitleSection 
        title="Basic Information" 
        subtitle="Help us organise your personalised portal by your basic information" 
      />
      <FormSection 
        onInputChange={handleInputChange} 
        defaultValues={formValues} 
      />
      <ButtonSection 
        onSubmit={handleSubmit} 
        onLater={handleLater} 
      />
    </div>
  );
};

export default OnBoarding;

