import React,{useEffect} from 'react';
import '../../css/PasswordUpdated.css';
import success_icon from "../../assets/success-icon.png";

const PasswordUpdated = ({ onDashboardClick }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className="password-updated-unique-container">
      <div className="success-icon-unique">
        <img 
          src={success_icon} 
          alt="Success" 
          width="96"
          height="82"
        />
      </div>
      
      <div className="message-container-unique">
        <h1 className="title-unique">Password Updated!</h1>
        <p className="description-unique">
          You have successfully updated your password.
        </p>
      </div>

      <button 
        className="dashboard-button-unique"
        onClick={onDashboardClick}
      >
        Go to Dashboard
      </button>
    </div>
  );
};

PasswordUpdated.defaultProps = {
  onDashboardClick: () => console.log('Dashboard button clicked')
};

export default PasswordUpdated;
