import React from 'react';
import PropTypes from 'prop-types';
import '../../../css/CalendarHeader.css';
import left from "../../../assets/left.png";
import right from "../../../assets/right.png";
// import Dropdown from "../../assets/Dropdown";
const CalendarHeader = ({ 
  currentDate, 
  onPrevMonth, 
  onNextMonth 
}) => {
  const monthYear = currentDate.toLocaleString('default', { 
    month: 'long', 
    year: 'numeric' 
  });

  return (
    <div className="calendar-header">
      <button 
        className="nav-button"
        onClick={onPrevMonth}
        aria-label="Previous month"
      >
        <img 
          src={left} 
          alt="Previous"
          width={18}
          height={18}
        />
      </button>
      
      <span className="month-year">{monthYear}</span>
      
      <button 
        className="nav-button"
        onClick={onNextMonth}
        aria-label="Next month"
      >
        <img 
          src={right} 
          alt="Next"
          width={18}
          height={18}
        />
      </button>
    </div>
  );
};

CalendarHeader.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  onPrevMonth: PropTypes.func.isRequired,
  onNextMonth: PropTypes.func.isRequired
};

CalendarHeader.defaultProps = {
  currentDate: new Date(),
  onPrevMonth: () => {},
  onNextMonth: () => {}
};

export default CalendarHeader;

