import React, { useState } from 'react';

import eye from "../../assets/eye.png"
import google from "../../assets/google.png"
import fb from "../../assets/fb.png"
import mail from "../../assets/mail.png"
import Eye_closed from "../../assets/eye-closed.png"
import GoogleLogin from "react-google-login";
import {googleAuth, login} from "../../action/auth";
import {useDispatch} from "react-redux";
import {showToast} from "../../App";
const SetPass= ({ onSubmit,history }) => {

  const dispatch=useDispatch()
  const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const otpClick=()=>{
    console.log(email)
  
}
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({ email, password });
    }
  };

 
  return (
    <div className="login-form">
      <div className="login-header">
        <h1>Set a New Password</h1>
        <p style={{width:"400px", fontSize:"15px"}}>Let’s set a strong password to maintain privacy</p>
      </div>

    
      <form onSubmit={handleSubmit} className="login-form-fields">
      <div className="input-group">
          <label htmlFor="password">Password</label>
          <div className="input-field">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="AT least 8 characters"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {
              showPassword?(<img 
                src={eye} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword(!showPassword)}
              />):(
                <img 
                src={Eye_closed} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword(!showPassword)}
              />

              )
            }
            
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="Confirm_password">Retype Password</label>
          <div className="input-field">
            <input
              id="re-password"
              type={showPassword2 ? "text" : "password"}
              placeholder="Password need to match"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {
              showPassword2?(<img 
                src={eye} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword2(!showPassword2)}
              />):(
                <img 
                src={Eye_closed} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword2(!showPassword2)}
              />

              )
            }
            
          </div>
        </div>

      

        
        <button type="submit" className="sign-in-button" onClick={otpClick}>
          Confirm New Password
        </button>
      </form>

      <div className="signup-section">
        
        <button className="sign-up-button" onClick={()=>history.push("/auth/register")}>Cancel</button>
      </div>
    </div>
  );
};

SetPass.defaultProps = {
  onSubmit: () => {},
  initialEmail: '',
  initialPassword: '',
  allowSocialLogin: true,
  allowPasswordReset: true,
  allowSignUp: true
};

export default SetPass;
