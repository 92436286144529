/* src/components/homepage/FormSection.js */
import React, { useState } from 'react';
import '../../../css/FormSection.css';
import Calendar from '../Calendar/Calendar';
import dropdown from "../../../assets/dropdown.png";

const FormSection = ({ onInputChange, defaultValues }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  // const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    defaultValues.dob instanceof Date 
      ? defaultValues.dob.toLocaleDateString('en-GB')
      : ''
  );

  const handleDateSelect = (date, formattedDate) => {
    setSelectedDate(formattedDate);
    onInputChange('dob', date);
  };

  return (
    <div className="form-section">
      <div className="input-field">
        <div className="label-frame">
          <label className="label-text">Your Name</label>
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="ex: johndoe@gmail.com"
            value={defaultValues.name}
            onChange={(e) => onInputChange('name', e.target.value)}
          />
        </div>
      </div>

      <div className="input-field">
        <div className="label-frame">
          <label className="label-text">Date of Birth</label>
        </div>
        <div className="input-container">
          <input
            type="text"
             placeholder="DD/MM/YYYY"
            value={selectedDate}
            onClick={() => setShowCalendar(!showCalendar)}
            readOnly
          />
              <img src={dropdown} alt="dropdown" className="input-icon caret-icon" />

          {showCalendar && (
            <div className="calendar-popup">
              <Calendar
              setShowCalendar={setShowCalendar}
                setSelectedDate={setSelectedDate}
                onDateSelect={handleDateSelect}
                // selectedDate={defaultValues.dob}
              />
            </div>
          )}
        </div>
      </div>

      <div className="input-field">
        <div className="label-frame">
          <label className="label-text">Class</label>
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Select"
            value={defaultValues.class}
            onChange={(e) => onInputChange('class', e.target.value)}
          />
          <img src={dropdown} alt="dropdown" className="input-icon caret-icon" />
        </div>
      </div>

      <div className="input-field">
        <div className="label-frame">
          <label className="label-text">School Name</label>
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Type here"
            value={defaultValues.school}
            onChange={(e) => onInputChange('school', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

FormSection.defaultProps = {
  onInputChange: () => {},
  defaultValues: {
    name: '',
    dob: new Date(),
    class: '',
    school: ''
  }
};

export default FormSection;
