import React, { useState, useEffect } from 'react';
import '../../css/OTPSubmit.css';

const OTPSubmit = ({ onSubmit, history}) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']); 

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
     
      if (value && index < 5) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  const handleSubmit = () => {
    const otpString = otp.join('');
    onSubmit?.(otpString);
  };
 const  onCancel = () => {history.goBack()}

  return (
    <div className="otp-submit-container">
      <div className="otp-content">
        <div className="otp-header">
          <h1 className="otp-title">Submit OTP</h1>
          <p className="otp-subtitle">Please provide the OTP sent to your email</p>
        </div>

        <div className="otp-input-section">
          <div className="otp-input-group">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-${index}`}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                className="otp-input"
              />
            ))}
          </div>
        </div>

        <div className="otp-buttons">
          <button className="submit-btn" onClick={handleSubmit}>
            Send OTP
          </button>
          <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

OTPSubmit.defaultProps = {
  onSubmit: (otp) => console.log('OTP submitted:', otp),

};

export default OTPSubmit;
