import React from 'react';
import '../../../css/TitleSection.css';

const TitleSection = ({ title, subtitle }) => {
  return (
    <div className="title-section">
      <h1 className="title-section__heading">{title}</h1>
      <p className="title-section__subheading">{subtitle}</p>
    </div>
  );
};

TitleSection.defaultProps = {
  title: 'Basic Information',
  subtitle: 'Help us organise your personalised portal by your basic information'
};

export default TitleSection;

