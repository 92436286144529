import React, { useState,useEffect } from 'react';
import '../../css/RegForm.css';
import eye from "../../assets/eye.png"
import google from "../../assets/google.png"
import fb from "../../assets/fb.png"
import mail from "../../assets/mail.png"
import Eye_closed from "../../assets/eye-closed.png"
import profile from "../../assets/profile.png"
import {register} from "../../action/auth";
import {useDispatch} from "react-redux";
import {showToast} from "../../App";
import {googleAuth} from "../../action/auth";
import GoogleLogin from "react-google-login";

const RegForm = ({ onSubmit ,history}) => {

      const dispatch=useDispatch()
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({ email, password });
    }
  };
   const googleAuthComplete=res=>{
      //console.log(res)
      if(res.accessToken!=undefined) {
          googleAuth({access_token: res.accessToken}, dispatch,history)
      }
  }
  

  const regClick=()=>{
   

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(name.length===0 || email.length===0 || password.length===0 || password2.length===0)
      showToast('Please fill up the fields properly...')
    else if(!emailRegex.test(email)) {
      showToast('Please enter a valid email address')
    }
    else{
      if(password!==password2)
        showToast('Please re-enter the password correctly')
      else{
        register({
          name:name,
          phone:email,
          password:password
        },dispatch,history)
      }
    }
}
  return (
    <div className="login-form">
      <div className="login-header">
        <h1>Hello Learner</h1>
        <p style={{width:"400px"}}>Let's login to your account and continue learning.</p>
      </div>

      <div className="social-buttons">
      <GoogleLogin
          clientId="384400786106-ep0igg2mrvq1f3vkvavpdlt7pkcp3d34.apps.googleusercontent.com"
          render={renderProps => (
            <button 
              onClick={renderProps.onClick} 
              disabled={renderProps.disabled} 
              className="social-button google"
            >
              <img src={google} alt="Google" className="social-icon" />
              <span>Sign in with Google</span>
            </button>
          )}
          onSuccess={googleAuthComplete}
          onFailure={googleAuthComplete}
          cookiePolicy={'single_host_origin'}
        />
        <button className="social-button facebook">
          <img src={fb} alt="Facebook" className="social-icon" />
          <span>Sign up with Facebook</span>
        </button>
      </div>

      <div className="divider">
        <div className="line"></div>
        <span>or Sign In with email</span>
        <div className="line"></div>
      </div>

      <form onSubmit={handleSubmit} className="login-form-fields">
      <div className="input-group">
          <label htmlFor="name">Full Name</label>
          <div className="input-field">
            <input
              id="name"
              type="text"
              placeholder="ex: John Doe"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <img src={profile} alt="email" className="field-icon" />
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="email">Email Address</label>
          <div className="input-field">
            <input
              id="email"
              type="email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src={mail} alt="email" className="field-icon" />
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <div className="input-field">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="*************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {
              showPassword?(<img 
                src={eye} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword(!showPassword)}
              />):(
                <img 
                src={Eye_closed} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword(!showPassword)}
              />

              )
            }
            
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="Confirm_password">Confirm Password</label>
          <div className="input-field">
            <input
              id="re-password"
              type={showPassword2 ? "text" : "password"}
              placeholder="*************"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            {
              showPassword2?(<img 
                src={eye} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword2(!showPassword2)}
              />):(
                <img 
                src={Eye_closed} 
                alt="toggle password"
                className="field-icon clickable"
                onClick={() => setShowPassword2(!showPassword2)}
              />

              )
            }
            
          </div>
        </div>

        <button type="submit" className="sign-in-button" onClick={regClick}>
          Sign Up
        </button>
      </form>

      <div className="signup-section">
        <p>Already Have an Account?</p>
        <button className="sign-up-button" onClick={()=>history.push("/auth/login")}>Sign In</button>
      </div>
    </div>
  );
};

RegForm.defaultProps = {
  onSubmit: () => {},
  initialEmail: '',
  initialPassword: '',
  allowSocialLogin: true,
  allowPasswordReset: true,
  allowSignUp: true
};

export default RegForm;
