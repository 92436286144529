import React, { useState, useEffect } from 'react';
import '../../css/LoginForm.css';
import eye from "../../assets/eye.png"
import google from "../../assets/google.png"
import fb from "../../assets/fb.png"
import mail from "../../assets/mail.png"
import Eye_closed from "../../assets/eye-closed.png"
import GoogleLogin from "react-google-login";
import {googleAuth, login} from "../../action/auth";
import {useDispatch} from "react-redux";
import {showToast} from "../../App";
const LoginForm = ({ onSubmit,history }) => {

  const dispatch=useDispatch()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  const loginClick=()=>{
    
    if(email.length===0 || password.length===0)
        showToast('Please enter valid login and password')
    else{
        login({
            phone:email,
            password:password
        },dispatch,history)
    }
}
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit({ email, password });
    }
  };

  const googleAuthComplete=res=>{
    //console.log(res)
    if(res.accessToken!=undefined) {
        googleAuth({access_token: res.accessToken}, dispatch,history)
    }
}

  return (
    <div className="login-form">
      <div className="login-header">
        <h1>Hello Learner</h1>
        <p style={{width:"400px"}}>Let's login to your account and continue learning.</p>
      </div>

      <div className="social-buttons">
        <GoogleLogin
          clientId="384400786106-ep0igg2mrvq1f3vkvavpdlt7pkcp3d34.apps.googleusercontent.com"
          render={renderProps => (
            <button 
              onClick={renderProps.onClick} 
              disabled={renderProps.disabled} 
              className="social-button google"
            >
              <img src={google} alt="Google" className="social-icon" />
              <span>Sign in with Google</span>
            </button>
          )}
          onSuccess={googleAuthComplete}
          onFailure={googleAuthComplete}
          cookiePolicy={'single_host_origin'}
        />
        <button className="social-button facebook">
          <img src={fb} alt="Facebook" className="social-icon" />
          <span>Sign in with Facebook</span>
        </button>
      </div>

      <div className="divider">
        <div className="line"></div>
        <span>or Sign In with email</span>
        <div className="line"></div>
      </div>

      <form onSubmit={handleSubmit} className="login-form-fields">
        <div className="input-group">
          <label htmlFor="email">Email Address</label>
          <div className="input-field">
            <input
              id="email"
              type="email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <img src={mail} alt="email" className="field-icon" />
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="password">Password</label>
          <div className="input-field">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="*************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {
              showPassword ? (
                <img 
                  src={eye} 
                  alt="toggle password"
                  className="field-icon clickable"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <img 
                  src={Eye_closed} 
                  alt="toggle password"
                  className="field-icon clickable"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )
            }
          </div>
        </div>

        <button type="button" className="forgot-password" onClick={()=>history.push("/auth/forgot")}>
          Forgot Password?
        </button>
        
        <button type="submit" className="sign-in-button" onClick={loginClick}>
          Sign In
        </button>
      </form>

      <div className="signup-section">
        <p>New Here?</p>
        <button className="sign-up-button" onClick={()=>history.push("/auth/register")}>Sign Up</button>
      </div>
    </div>
  );
};

LoginForm.defaultProps = {
  onSubmit: () => {},
  initialEmail: '',
  initialPassword: '',
  allowSocialLogin: true,
  allowPasswordReset: true,
  allowSignUp: true
};

export default LoginForm;
