import React from 'react';
import '../../css/IllustrationSection.styles.css';
import login from "../../assets/login.png"

const IllustrationSection = ({altText,description}) => {
  return (
    <div className="illustration-section">
      <div className="description" style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
          {description}
        </div>
      <div className="illustration-container">
      
        <img 
          src={login} 
          alt={altText}
          className="main-illustration"
        />
        
         
      </div>
    
    </div>
  );
};

IllustrationSection.defaultProps = {
   altText: 'Main Illustration',
  description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s.'
};

export default IllustrationSection;

